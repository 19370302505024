
import editorjs_configs from "./configs"
import EditorJS from '@editorjs/editorjs';


export default function () {

    document.addEventListener('DOMContentLoaded', () => {

        let $editor = $('#'+editorjs_configs.holder)

        if ($editor.length === 0){
            return
        }

        // debug
        // let data_str = $('#editorjs').attr('data-editorjs');
        // const data = JSON.parse( data_str || '{}')
        // if(data_str) {
        //     console.log('HTML:')
        //     console.log(html_parser.parseStrict(data))
        // }

        $('#new_article, .edit_article').each((i, form) => {

            editorjs_configs.data = JSON.parse($('#editorjs').attr('data-editorjs') || "{}")

            // debug
            console.log(editorjs_configs)

            const text_editor = new EditorJS(editorjs_configs);
            // window.text_editor = text_editor;
            form.text_editor = text_editor;


            $(form).submit(function (event) {
                let save_scope = this

                text_editor.save()
                    .then((savedData) => {
                        $(save_scope).append(
                            `<textarea name="article[editorjs_data]"> ${JSON.stringify(savedData)} </textarea>`
                        );
                    })
                    .catch((error) => {
                        console.error('Saving error', error);
                        event.preventDefault();
                    });


                return true
            });
        })

    })
};