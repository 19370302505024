import locale from './locale.ru'

// =========  EditorJs Tools  ============
// base
const Paragraph = require('@editorjs/paragraph');
const Header = require('@editorjs/header');
const Quote = require('@editorjs/quote');
import List from '@editorjs/list';
const Delimiter = require('@editorjs/delimiter');
import Underline from '@editorjs/underline';

// todo: do we need this?
// const Warning = require('@editorjs/warning');
// warning: {
//     class: Warning,
//         inlineToolbar: true,
//         shortcut: 'CMD+SHIFT+W',
//         config: {
//         titlePlaceholder: 'Title',
//             messagePlaceholder: 'Message',
//     },
// },

const Embed = require('@editorjs/embed');
const RawTool = require('@editorjs/raw');

import ImageTool from './image-tool';
const ImageGallery = require('@rodrigoodhin/editorjs-image-gallery');
import TextVariantTune from '@editorjs/text-variant-tune';

// todo: implement backend
// const LinkTool = require('@editorjs/link');
// const Personality = require('@editorjs/personality');
//personality: {
//       class: Personality,
//       config: {
//         endpoint: 'http://localhost:8008/uploadFile'  // Your backend file uploader endpoint
//       }
//     }


let configs = {
    /**
     * Id of Element that should contain the Editor
     */
    holder: 'editorjs',
    i18n: locale,
    logLevel: 'INFO',
    placeholder: 'Let`s write an awesome story!',

    inlineToolbar: ['bold', 'italic', 'underline', 'link' ],

    /**
     * Available Tools list.
     * Pass Tool's class or Settings object for each Tool you want to use
     */
    tools: {

        paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            tunes: ['textVariant']
        },

        header: {
            class: Header,
            inlineToolbar: true,
            config: {
                shortcut: 'CMD+SHIFT+H',
                placeholder: 'Enter a header',
                levels: [3, 4],
                defaultLevel: 3
            }
        },

        list: {
            class: List,
            inlineToolbar: true,
        },

        image: {
            class: ImageTool,
            config: {
                endpoints: {
                    byFile: '/admin/photos/upload_file', // Your backend file uploader endpoint
                    byUrl: '/admin/photos/upload_url', // Your endpoint that provides uploading by Url
                },
                field: 'image',
                types: "image/jpg', 'image/gif', 'image/png",
                additionalRequestHeaders: {
                    "X-CSRF-Token": document.querySelector('meta[name=\'csrf-token\']').content
                },

                buttonContent: '<div class="">Выбрать изображение</div>',
                onSelectFileClick: function (image_tool){
                    PhotoManager.get_image_tool_data((response)=>{
                        image_tool.onUpload(response)
                    })
                }
            }
        },

        imageGallery: ImageGallery,

        delimiter: Delimiter,

        quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
                quotePlaceholder: 'Цитата',
                captionPlaceholder: 'Автор цитаты',
            },
        },

        raw: {
            class: RawTool,
            inlineToolbar: true,
            placeholder: 'Raw HTML'
        },

        embed: {
            class: Embed,
            inlineToolbar: true,

            config: {
                services: {
                    youtube: true,
                    coub: true,
                    facebook: true,
                    instagram: true,
                    twitter: true,
                    twitch_video: true,
                    vimeo: true,
                    gfycat: true,
                    vine: true,
                    pinterest: true

                }
            }
        },

        underline: Underline,
        textVariant: TextVariantTune,

    }
}

export default configs;