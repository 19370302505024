export default {
    messages: {
        ui: {
            "blockTunes": {
                "toggler": {
                    "Click to tune": "Нажмите, чтобы настроить",
                    "or drag to move": "или перетащите"
                },
            },
            "inlineToolbar": {
                "converter": {
                    "Convert to": "Конвертировать в"
                }
            },
            "toolbar": {
                "toolbox": {
                    "Add": "Добавить"
                }
            }
        },

        toolNames: {
            "Text": "Параграф",
            "Heading": "Заголовок",
            "List": "Список",
            "Warning": "Примечание",
            "Checklist": "Чеклист",
            "Quote": "Цитата + автор",
            "Code": "Код",
            "Delimiter": "Разделитель",
            "Raw HTML": "HTML-фрагмент",
            "Table": "Таблица",
            "Link": "Ссылка",
            "Marker": "Маркер",
            "Bold": "Полужирный",
            "Italic": "Курсив",
            "InlineCode": "Моноширинный",
            "Underline": "Подчеркивание",
            "Image": "Картинка",
            "Image Gallery": "Галерея"
        },

        tools: {
            /**
             * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
             * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
             */
            "warning": { // <-- 'Warning' tool will accept this dictionary section
                "Title": "Название",
                "Message": "Сообщение",
            },

            /**
             * Link is the internal Inline Tool
             */
            "link": {
                "Add a link": "Вставьте ссылку"
            },
            /**
             * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
             */
            "stub": {
                'The block can not be displayed correctly.': 'Блок не может быть отображен'
            },

            "image" : {
                "Caption": "Описание"
            }
        },

        blockTunes: {
            /**
             * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
             * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
             *
             * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
             */
            "delete": {
                "Delete": "Удалить"
            },
            "moveUp": {
                "Move up": "Переместить вверх"
            },
            "moveDown": {
                "Move down": "Переместить вниз"
            },
            "textVariant": {
                "Call-out": "Вставка",
                "Citation": "Цитата",
                "Details": "Детали"
            },

            "withBorder": {
                "With border": "Рамка",
            },

            "stretched": "Растянуть",

            "withBackground": {
                "With background": "С фоном"
            }

        }
    }
}